<template>
  <surround-box
    specialColor="#093243"
    background="linear-gradient(270deg, rgba(21, 101, 182, 0) 0%, #3B7AC2 50%, rgba(21, 101, 182, 0) 95%)"
    title="生猪粪污年转运统计"
  >
    <div class="chart-box">
      <div ref="chart" class="chart"></div>
      <div class="unit">单位：吨</div>
    </div>
  </surround-box>
</template>
<script>
import surroundBox from "@/components/mapComp/surroundBox2.vue";
import * as echarts from "echarts";
export default {
  components: {
    surroundBox,
  },
  data() {
    return {
      zhuanyun: [
        // {
        //   year: "2016",
        //   num: 12.1,
        // },
        // {
        //   year: "2017",
        //   num: 13.1,
        // },
        // {
        //   year: "2018",
        //   num: 12.8,
        // },
        // {
        //   year: "2019",
        //   num: 13.6,
        // },
        // {
        //   year: "2020",
        //   num: 13.9,
        // },
      ],
      jiagong: [
        {
          year: "2016",
          num: 5.2,
        },
        {
          year: "2017",
          num: 5.7,
        },
        {
          year: "2018",
          num: 5.6,
        },
        {
          year: "2019",
          num: 6.4,
        },
        {
          year: "2020",
          num: 6.9,
        },
      ],
      shiyong: [
        {
          year: "2016",
          num: 4.4,
        },
        {
          year: "2017",
          num: 4.8,
        },
        {
          year: "2018",
          num: 4.6,
        },
        {
          year: "2019",
          num: 5.1,
        },
        {
          year: "2020",
          num: 5.2,
        },
      ],
      //年份
      year: "",
    };
  },
  methods: {
    //   初始化
    initChart() {
      let chart = echarts.init(this.$refs.chart);
      let options = {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          containLabel: true,
          top: 48,
          left: 16,
          right: 32,
          bottom: 16,
        },
        xAxis: {
          data: this.zhuanyun.map((item) => item.year),
          axisLabel: {
            color: "#fff",
          },
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: ["#fff"],
              type: "dashed",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["rgba(255,255,255,0.5)"],
              type: "dashed",
            },
          },
        },
        yAxis: {
          axisLabel: {
            color: "#fff",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["rgba(255,255,255,0.5)"],
              type: "dashed",
            },
          },
        },
        series: [
          {
            type: "line",
            name: "转运数量",
            data: this.zhuanyun.map((item) => item.num),
            lineStyle: {
              color: "rgba(14, 156, 255, 1)",
            },
            areaStyle: {
              color: "rgba(14, 156, 255, 0.5)",
            },
          },
          {
            type: "line",
            name: "加工数量",
            data: this.jiagong.map((item) => item.num),
            lineStyle: {
              color: "rgba(151, 14, 255, 1)",
            },
            areaStyle: {
              color: "rgba(151, 14, 255, 0.5)",
            },
          },
          {
            type: "line",
            name: "施用数量",
            data: this.shiyong.map((item) => item.num),
            lineStyle: {
              color: "rgba(255, 14, 54, 1)",
            },
            areaStyle: {
              color: "rgba(255, 14, 54, 0.5)",
            },
          },
        ],
      };
      chart.setOption(options);
    },
    //转运统计
    getZhuanyun() {
      this.$post(this.$api.STATICS_MANAGE.GET_ZHAUNYUN, {
        startTime: `${this.year - 5}-01-01 00:00:01`,
        endTime: `${this.year}-12-31 23:59:59`,
      }).then((res) => {
        for (let i = 0; i < 6; i++) {
          this.zhuanyun.push({
            year: 2016 + i,
            num: res[2016 + i],
          });
        }
        this.initChart();
      });
    },
    getDate() {
      let dates = new Date();
      this.year = dates.getFullYear();
      this.getZhuanyun();
    },
  },
  mounted() {
    this.getDate();
  },
};
</script>
<style lang='less' scoped>
.chart-box {
  width: 100%;
  height: 220px;
  position: relative;
  .chart {
    width: 100%;
    height: 230px;
  }
  .unit {
    position: absolute;
    left: 16px;
    top: 12px;
    color: #fff;
    font-weight: bold;
  }
}
</style>